<template>
  <div class="page adjust_width stats">

<!--    <div class="under_construct">-->
<!--      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23 10.826v2.349c-1.562 0-3 1.312-3 2.857 0 2.181 1.281 5.968-6 5.968v-2.002c4.917 0 3.966-1.6 3.966-3.967 0-2.094 1.211-3.5 2.278-4.031-1.067-.531-2.278-1.438-2.278-3.312 0-2.372.94-4.692-3.966-4.686v-2.002c7.285 0 6 4.506 6 6.688 0 1.544 1.438 2.138 3 2.138zm-19-2.138c0-2.182-1.285-6.688 6-6.688v2.002c-4.906-.007-3.966 2.313-3.966 4.686 0 1.875-1.211 2.781-2.278 3.312 1.067.531 2.278 1.938 2.278 4.031 0 2.367-.951 3.967 3.966 3.967v2.002c-7.281 0-6-3.787-6-5.969 0-1.545-1.438-2.857-3-2.857v-2.349c1.562.001 3-.593 3-2.137zm9 5.312v1.278c0 1.143-.722 2.068-1.774 2.276l-.199-.431c.487-.184.797-.773.797-1.216h-.824v-1.907h2zm0-6h-2v2h2v-2z"/></svg>-->
<!--      <span>Page en conception</span>-->
<!--    </div>-->
    
    <div class="info">Cette page est en cours d'évolution, retrouvez-ici très prochainement vos statistiques détaillées, vos top produits et bien plus encore.</div>
    
    <title_cat>Historique des commandes</title_cat>
    
    
    <div v-if="commandes">
      
    <div class="commande" v-for="n in commandes" :key="n.id">
      <div class="b">
        <div class="top" @click="open_s(n.id)">
          <div v-if="n.nom" class="name capitalize">
            {{ n.prenom }} {{ n.nom[0] }}.
            <div class="num">{{ n.user_tel }}</div>
          </div>
          <div class="r">
            <div class="date">{{ n.at | formatday('ddd DD/MM HH:mm') }}</div>
            <div class="prix">{{ n.price | formatNumberdecimal }}€</div>
          </div>
        </div>
        <div class="details" v-if="open[n.id]">
          <div class="article" v-for="(d, k) in n.articles.articles" :key="k">
          <div class="line">
            <div class="nb">{{ d.nb }}</div>
            <div class="name"><div class="img"><el-image :src="$img_path + d.data.img_key" fit="contain"></el-image></div> <div class="n">{{ d.data.name }}</div></div>
            <div class="price">{{ d.data.final_price + d.options.total | formatNumberdecimal }}€</div>
          </div>
          <div class="options" v-if="d.options.options.length">
            <div class="o" v-for="(o, k1) in d.options.options" :key="k1">
              <i class="plus el-icon-arrow-right"></i>
              {{ o.name }}
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>

      <p v-if="!Object.keys(commandes).length" class="info_ico"><i class="el-icon-document-delete"></i> Pas encore de commandes.</p>
      
    </div>
  </div>
</template>

<script>

export default {
  name: "stats",
  data() {
    return {
      commandes: [],
      open: {}
    }
  },
  methods: {
    open_s(id) {
      this.open[id] = !this.open[id]
      this.$forceUpdate()
    }
  },
  created() {
    this.$http.get('/bo/boutique/commandes/pay').then((response) => {
      this.commandes = response.data
    })
  }
}
</script>

<style scoped>
  
  .stats { }

  .commande .details { font-size: 16px }
  .commande .details .article { display: flex; min-height: 50px; flex-direction: column; justify-content: center; padding: 0 0 0 16px; border-bottom: 1px solid #272d3c; cursor: pointer; }
  .commande .details .article .line { display: flex; align-items: center; height: 50px; }
  .commande .details .article .options { padding-left: 74px; padding-bottom: 16px; }
  .commande .details .article .options .o { min-height: 24px; display: flex; align-items: center; font-size: 14px; color: var(--white-sub) }
  .commande .details .article .options .o .plus { background: #1d1f25; border-radius: 100%; padding: 2px; color: var(--white-sub); font-size: 12px; margin-right: 5px; }
  .commande .details .article .nb { width: 35px; font-size: 20px; color: var(--black-sub) }
  .commande .details .article .name { width: calc(100% - 115px); display: flex; align-items: center; }
  .commande .details .article .name .n { font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .commande .details .article .name .img { width: 30px; min-width: 30px; height: 30px; background: #eee; margin-right: 8px; border-radius: 2px; overflow: hidden }
  .commande .details .article .price { width: 80px; text-align: right; color: var(--black-sub) }
  
  .commande { margin-bottom: 6px!important; }
  .commande .b { background: var(--black-list); height: 100%; padding: 0 16px; border-radius: 2px }
  .commande .b .top { display: flex; align-items: center; height: 50px; font-size: 18px; justify-content: space-between; cursor: pointer }
  .commande .b .top .r { display: flex; align-items: center }
  .commande .b .top .r .prix { margin-left: 16px; }
  .commande .b .name { color: var(--white-main) }
  .commande .b .name .num { font-size: 13px; color: var(--black-sub); padding-top: 1px; font-weight: 400; }
  .commande .b .prix { color: var(--white-main); font-weight: 600; }
  .commande .b .date { color: var(--white-sub); font-size: 14px }
  
  .info { color: var(--white-sub); font-size: 14px; margin-bottom: 16px; padding: 0 16px }


  @media only screen and (min-width: 800px) {
    .info { width: 400px; padding: 0; margin-top: 6px; }
    
  }
  
</style>